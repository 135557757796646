import axios from 'axios';
import {getCookie} from '@/static/js/cookie';
import Vue from 'vue'
// 创建一个axios实例
const service = axios.create({

  headers: {
    'content-type':"application/x-www-form-urlencoded; charset=UTF-8",
    // 'Authorization': getCookie("token")?JSON.parse(getCookie("token")).token:''
  },
  
  baseURL: process.env.VUE_APP_ApiUrl||'',
  withCredentials: true,
  timeout: 5000
})


// 添加请求拦截器
service.interceptors.request.use(config => {
  // 在发送请求之前做某事，比如说 设置token
  // config.headers['Token'] = getCookie("token")?JSON.parse(getCookie("token")).token:'';
  //config.headers['X-Token'] = 'xxxxxxxxxxxxxxxxxxx';

  //如果是本地的，多加一个参数，
  let session_id=''
  if (process.env.NODE_ENV === 'development') {
      session_id=getCookie('session_id')
      config.params={...config.params,session_id:session_id}
  }

  return config;

}, error => {
  // 请求错误时做些事
  return Promise.reject(error);
});


// 添加响应拦截器
service.interceptors.response.use(response => {
  const res = response.data;
// 如果返回的状态不是200 就主动报错
  if(res.status === 0){
    Vue.prototype.$message.error(res.errmsg);
    // return Promise.reject(res.errmsg || 'error')
    return
  }

  return res;
}, error => {
    return Promise.reject(error.response.data); // 返回接口返回的错误信息
});

//给接口加上网站地址


export function get(url, data = {}) {
  return service({
    method: 'get',
    url: url,
    params:data
  })
}
export function getBlob(url, data = {}) {
  return service({
    method: 'get',
    url: url,
    params:data,
    responseType: 'blob'
  })
}
export function post(url, data = {}) {
  return service({
    method: 'post',
    url:url,
    data:data
  })
}