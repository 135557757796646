import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
import {setCookie,removeCookie} from '@/static/js/cookie';
import { asyncRouterMap, constantRouterMap } from '@/router';
import { login, getUserInfo } from '@/axios/login'

function hasPermission(roles, route) {//递归遍历是否有权限
  if (route.meta && route.meta.roles) {
    return  route.meta.roles.includes(roles)//有设置权限的判断是否有权限
  } else {
    return true//没设置权限的都是有权限
  }
}


const store = new Vuex.Store({//定义数据
  state: {
    user:{
      routers: constantRouterMap,
      addRouters: [],
      roles:'',
      token:"",
    }
  },
  mutations: {
    SET_ROUTERS: (state, routers) => {
      state.user.addRouters = routers;
      state.user.routers = constantRouterMap.concat(routers);
    },
    SET_ROLES: (state, roles) => {
      state.user.roles = roles
    },
    SET_TOKEN: (state, token) => {
      state.user.token = token
    },
  },
  getters:{
    roles: state => state.user.roles,  
    addRouters: state => state.user.addRouters,
    getRouters: state => state.user.routers,
    token: state => state.user.token,
  },
  actions: {
    // 用户名登录
    Login({ commit },userInfo ) {
      return new Promise((resolve, reject) => {
        login(userInfo).then(response => {
          console.log(response,"登录返回的值")
          const data = response
          if(data.status==1){
            console.log("登陆成功",data)
            // setCookie("token",JSON.stringify(data.data))
            setCookie("token",JSON.stringify({role:'super'}))
            commit('SET_TOKEN', data.data)//假数据会返回data，保存token为data，如果不是假数据没有返回data，自己定义个字符串

            resolve(response.data)
          }else{
            reject(data.errmsg)
          }
          
        })
      })
    },
    // 登出
    LogOut({ commit }) {
      return new Promise(resolve => {
          commit('SET_TOKEN', '')
          commit('SET_ROLES', [])
          removeCookie('token')
          resolve()
        })
    },
   
    //生成可访问路由
    GenerateRoutes({ commit }, roles) {
      return new Promise(resolve => {
        const accessedRouters = asyncRouterMap.filter(v => {
          // if (roles=='super') return true;//如果是最高权限的管理员，就直接全返回所有路由
          if (hasPermission(roles, v)) {//如果返回true说明有权限，在递归子元素
            if (v.children && v.children.length > 0) {
              v.children = v.children.filter(child => {
                if (hasPermission(roles, child)) {
                  return child
                }
                return false;
              });
              return v
            } else {
              return v
            }
          }
          return false;
        });
        commit('SET_ROUTERS', accessedRouters);//添加有权限的路由

        resolve();
      })
    },
  }
})


  export default store
